$darkBlack: #0d2641;
$nightWhite: #ebede6;
$borderWidth: 3px;

$blue: #185da9;
$gold: #fdba4a;
$red: #df5108;
.site-content.pfactory {
  transition: background-color 0.3s;
}
.cart.minimized {
  transition: background-color 0.3s;
}
.pages-nav {
  transition: background-color 0.3s;
}
.site-content.pfactory.dark {
  color: $nightWhite;
  background-color: $darkBlack;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='48' height='48' viewBox='0 0 48 48'%3E%3Cg fill='%23d8d8d8' fill-opacity='0.03'%3E%3Cpath d='M12 0h18v6h6v6h6v18h-6v6h-6v6H12v-6H6v-6H0V12h6V6h6V0zm12 6h-6v6h-6v6H6v6h6v6h6v6h6v-6h6v-6h6v-6h-6v-6h-6V6zm-6 12h6v6h-6v-6zm24 24h6v6h-6v-6z'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
  transition: background-color 0.3s;
}
.pfactory.dark {
  // img.nav-bar-logo {
  //   box-sizing: border-box;
  //   background: url(https://afag.imgix.net/harvest-moon/logo-dark-2.png?h=90) no-repeat;
  //   background-size: contain;
  //   width: 204px;
  //   height: 42px;
  //   margin-left: 5px;
  //   padding-left: 204px !important;
  // }
  // .cart-wrapper:after {
  //   background:
  //       linear-gradient(-45deg, transparent 16px, $blue 0),
  //       linear-gradient(45deg, transparent 16px, $blue  0);
  //     background-repeat: repeat-x;
  //   background-position: left bottom;
  //   background-size: 22px 32px;
  //   content: "";
  //   display: block;

  // width: 100%;
  // height: 13px;

  //  position: relative;
  // // top:64px;
  // left:0px;
  // }
  .cart.minimized {

    color: $gold;
    background: $blue;
    border-bottom: none;
    .checkout-button {
      background: $gold;
      color: black;
    }

  }
  a.page-link {
    font-weight: 400;
    color: $nightWhite;
    border-bottom: $borderWidth solid $darkBlack;
    &:hover, &.active {
      color: $nightWhite;
      border-bottom: $borderWidth solid $red;
    }
  }
  .pages-nav {
    color: $nightWhite;
    // background: $darkBlack;
  }
  .quick-links {
    background: $darkBlack;
    color: $nightWhite;
  }
}

.pfactory.dark {
  .landing-page {
    // background-image: url(https://images.unsplash.com/photo-1542319920155-a236a55f1a97?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1000&q=50);
    // background-position: 0% 50%;

  }


  a.page-link {
    color: $nightWhite;
    border-bottom-color: $darkBlack;
    &:hover, &.active {
      border-bottom-color: $nightWhite;
    }
  }

  .landing-page {
    .text {
      color: $nightWhite;
      // background: rgba(0,0,0,0.7);
    }
  }
  .footer {
    color: $gold;
    background: url(https://afag.imgix.net/beachwood-cafe/stone-tower.png?w=700&invert=true) no-repeat;
    background-position: right 20%;
    background-size: 350px;
    min-height: 500px;
    padding-bottom: 0;
  }


}
$darkCheckoutPaneColor: #185500;
.dark {
  .menu-story .category-name .text {
    color: $nightWhite;
  }
  .product-wrapper {
    color: $nightWhite;
    background-color: #222;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3E%3Cpath fill='%23bdbdbd' fill-opacity='0.18' d='M1 3h1v1H1V3zm2-2h1v1H3V1z'%3E%3C/path%3E%3C/svg%3E");
    .product-info {
      color: $nightWhite;
    }
    .panel, .product-modgroup {
      background: #333;
      box-shadow: 0px 2px 4px #0f0f0f;
    }
  }

  .checkout-container {
    .step-number {
      background: $nightWhite;
      color: $darkBlack;
    }
  }
  .actions {
    .button {
      color: $darkBlack;
      font-weight: 500;
    }
  }
  .cart-type-container {
    background: $darkCheckoutPaneColor;
    color: $nightWhite;
  }

  .checkout__cash-payment {
    color: $nightWhite;
    background: $darkCheckoutPaneColor;
  }
  .price-summary {
    color: $nightWhite;
  }
  .switch-button-group, .cart-type-container {
    color: $nightWhite;
    label.selected {
      background: $darkCheckoutPaneColor;
    }
    label.not-selected {
      background: #8bb487;
    }
  }
  .place-order-section {
    background: $darkCheckoutPaneColor;
    .place-order-button {
      background: #16b7ff;
    }
  }
  .footer {
    background: #111;
  }
}
